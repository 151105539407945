import * as React from "react"

const HomePage = () => {
    return (
        <main>
            <p>Hello World</p>
        </main>
    )
}

export default HomePage